<template>
  <transition appear>
    <div class="live-room-wrapper " id="container">
        <!-- BOX DE Carregamento da página SPINNER -->
      <div class="naming-box container" v-if="loadingPage">
          <b-spinner class="jb-big-spinner" label="Large Spinner" type="grow" variant="primary"></b-spinner>
      </div>
        <!-- BOX DE ERRO NO CARREGAMENTO -->
      <div class="naming-box container" v-if="!loadingPage && roomError">
          <b-row>
            <b-col md="6" class="mx-auto pt-5 text-center">
              <b-card 
                :header="$t('Oops')"
                header-text-variant="white"
                header-tag="h3"
                header-bg-variant="danger"
                class="shadow border border-info"
                >
                <b-card-text>
                  {{$t(errorMessage)}}
                </b-card-text>
                <b-button type="button" variant="danger"  @click="returnNavigation">{{$t('Ok')}}</b-button>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <!-- BOX DE AGRADECIMENTO -->
      <div class="naming-box container" v-if="!roomError" v-show="!loadingPage && !openRoom && isSessionClosed">
          <b-row>
            <b-col md="6" class="mx-auto pt-5 text-center">
              <b-card 
                :header="$t('Thank you for your participation')"
                header-text-variant="white"
                header-tag="h3"
                header-bg-variant="info"
                class="shadow border border-info"
                >
                <b-button type="button" variant="outline-green"  class="mb-2" pill  @click="returnNavigation">
                   {{$t('Until next time')}} <i class="fa fa-thumbs-up"></i>
                </b-button>

                <iframe v-if="$i18n.locale == 'pt-BR'" src="https://docs.google.com/forms/d/e/1FAIpQLSe8Ep2Olby6pTYdGR2HhzawNd_ISDSgJb65EwVVG_ekjX0wCA/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                
              </b-card>
            </b-col>
          </b-row>
        </div>
        <!-- BOX DE INICIO SETANDO NOME DO USUARIO + TESTE -->
        <div class="naming-box container" v-if="!roomError" v-show="!loadingPage && !openRoom && !isSessionClosed">
          <b-row>
            <b-col md="12" class="mx-auto mt-2">
              <b-card 
                header-bg-variant="info" 
                header-text-variant="white" 
                class="border shadow" 
                :header="$t('Participant information')">
                <b-row>
                  <b-col :md="blindEnabled ? '4' : '6'" v-show="!enableCanvasMediaStream">
                    <b-card :header="$t('Your camera')" class="border">
                        <div id="test-video">
                          <video id="input_video"></video>
                        </div>
                    </b-card>
                  </b-col>
                  <b-col :md="blindEnabled ? '4' : '6'" v-show="enableCanvasMediaStream">
                    <b-card :header="blindEnabled ? $t('Your anonymous image') : $t('Your camera')" class=" border">
                    <div id="output_elements">
                      <canvas id="output_canvas" width="320px" height="240px"></canvas>
                      <video id="output_video" class="d-none"></video>
                    </div>
                    </b-card>
                  </b-col>
                  <b-col :md="blindEnabled ? '4' : '6'">
                    <b-card :header="$t('Settings')" class=" border">
                      <b-form @submit.stop.prevent>
                        <div class="notLoggedIn" v-if="!this.empresa">
                          <b-form-group :label="$t('Enter your name')" label-for="text-nickname"  >
                            <b-input id="text-nickname" v-model="userName" maxlength="255"></b-input>
                          </b-form-group>
                          <b-form-group :label="$t('Your e-mail here')" label-for="text-email"  >
                            <b-input id="text-email" v-model="userEmail" maxlength="255"></b-input>
                          </b-form-group>
                        </div>
                        <div class="loggedIn" v-else>
                          <h3> {{$t('Hello')}}, {{primeiroNome}} </h3>

                        </div>
                        <!-- SELECT DO DEVICE DE VIDEO -->
                        <b-form-group :label="$t('Select your camera')" label-for="video-device-select" >
                          <b-form-select id="video-device-select" v-model="currentVideoDeviceId"
                            @change="toggleTestCamera(currentVideoDeviceId)" >
                            <template #first>
                              <b-form-select-option value="">{{$t("Default Device")}}</b-form-select-option>
                            </template>
                            <b-form-select-option :value="vDevice.deviceId" v-for="(vDevice,index) in videoDevices" :key="index">
                              {{vDevice.label}}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                        <!-- SELECT DO DEVICE DE AUDIO -->
                        <b-form-group :label="$t('Select your microphone')" label-for="audio-device-select" >
                          <b-form-select id="audio-device-select" v-model="currentAudioDeviceId"
                            @change="toggleTestAudio(currentAudioDeviceId)" >
                            <template #first>
                              <b-form-select-option value="">{{$t("Default Device")}}</b-form-select-option>
                            </template>
                            <b-form-select-option :value="vDevice.deviceId" v-for="(vDevice,index) in audioDevices" :key="index">
                              {{vDevice.label }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-form>
                      <div class="d-flex justify-content-between my-3">
                        <b-button type="button" variant="light" pill class=" border" 
                        @click="toggleBackgroundSidebar" 
                        v-if="ovManager.enableCustomBack"
                        v-b-tooltip.hover
                        :title="$t('Configuring background image')">
                          <i class="fas fa-magic"></i>
                        </b-button>
                        <b-button type="button" variant="blue" pill :disabled="!userName" @click="closeTestVideo">
                          {{$t('Enter')}}
                          <i class=" ml-1 fas fa-play"></i>
                        </b-button>
                        
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
                
              </b-card>
            </b-col>
          </b-row>
        </div>
        <template>
          <transition name="slide-fade">
            <div id="layout" class="bounds"  v-show="!loadingPage && openRoom && !isSessionClosed && !roomError">
              <div 
                v-for="(sub,i) in subscribers" :key="'remoteUser'+sub.streamManager.stream.streamId" 
                :class="{
                  'remoteUsers OT_root OT_publisher custom-class': true, 
                  'OV_big': subscribers[i].isScreenShareActive()
                }"
                :style="{
                  'display': (i > (ovManager.MAX_PARTICIPANTS_IN_ROOM -1) && !subscribers[i].isScreenShareActive()) ? 'none' : 'block'
                }"
                :id="'remoteUsers-'+sub.streamManager.stream.streamId">
                <div class="overlaySharedScreen" v-if="subscribers[i].isScreenShareActive() && screenSharePublisher"></div>
                <stream 
                  v-model="subscribers[i]" 
                  :index="i"
                  :maxParticipants="ovManager.MAX_PARTICIPANTS_IN_ROOM"
                  :numberOfHiddenParticipants="ovManager.subscribersList.length - ovManager.MAX_PARTICIPANTS_IN_ROOM +1"
                  :streamId="sub.streamManager.stream.streamId"
                  :canApprove="canApprove"
                  :isAuthenticatedUser="isAuthenticatedUser"
                  :showMoreFilters="showMoreFilters"
                  @showSubscriber="showSubscriber"
                  @showEvalSidebar="showEvalSidebar"
                  @muteSubscriber="muteSubscriber"
                  @removeParticipant="removeParticipant"
                  @changeSubscriberAudoFilter="changeSubscriberAudoFilter"  />
              </div>
              <div :key="'localUser'" 
              :class="{'OT_root OT_publisher custom-class': true, 'OV_big': false }"
              id="localUser" v-if="user && !!user.getStreamManager()">
                <stream ref="localStream" v-model="user" @handleNickname="nicknameChanged" :blind="blindEnabled" />
              </div>      
            </div>   
          </transition>
        </template>   
        <div class="chat-room" v-show="!loadingPage && chatDisplay !== 'none'" v-if="user && !!user.getStreamManager()">
          <chat
            :user="user"
            :chatDisplay="chatDisplay"
            :message-list="messageList"
            @close="toggleChat"
            :blindEnabled="blindEnabled"
            @messageReceived="checkNotification"
          />
        </div>
        <top-nav-bar
            :sessionId="sessionName"
            v-model="user"
            :showNotification="showNotification"
            :isAuthenticatedUser="isAuthenticatedUser"
            :fullscreen="fullscreen"
            :sharing="showShare"
            :recordingEnabled="recordingEnabled"
            :permitRecord="permitRecord"
            :blindEnabled="blindEnabled"
            :videoDevices="videoDevices"
            :audioDevices="audioDevices"
            :subscribers="subscribers"
            :enableCustomBack="ovManager.enableCustomBack"
            :currentRAToken="currentRAToken"
            :canApprove="canApprove"
            @camStatusChanged="camStatusChanged"
            @micStatusChanged="micStatusChanged"
            @screenShare="screenShare"
            @toggleDevices="toggleDevices"
            @stopScreenShare="stopScreenShare"
            @toggleFullscreen="toggleFullscreen"
            @toggleBackgroundSidebar="toggleBackgroundSidebar"
            @removeParticipant="removeParticipant"
            @leaveSession="leaveSession"
            @toggleChat="toggleChat"
            @audioFilter="applyGStreamerFilterAudio"
            @videoFilter="applyFaceOverlayFilter"
            @audioCustomFilter="audioCustomFilter"
            @startRecording="startRecording"
            @stopRecording="stopRecording"
            @showSubscriber="showSubscriber"
            @showEvalSidebar="showEvalSidebar"
            @muteSubscriber="muteSubscriber"
            @showManual1Visible="showManual1Visible"
            @showFixedAlert="showFixedAlert=!showFixedAlert"
            @changeSubscriberAudoFilter="changeSubscriberAudoFilter"
            v-show="!loadingPage && openRoom && !isSessionClosed  && !roomError"
          />
        <toolbar v-show="!loadingPage && openRoom && !isSessionClosed  && !roomError"
            :sessionId="sessionName"
            v-model="user"
            :showNotification="showNotification"
            :isAuthenticatedUser="isAuthenticatedUser"
            :fullscreen="fullscreen"
            :sharing="showShare"
            :recordingEnabled="recordingEnabled"
            :permitRecord="permitRecord"
            :blindEnabled="blindEnabled"
            :videoDevices="videoDevices"
            :audioDevices="audioDevices"
            @camStatusChanged="camStatusChanged"
            @micStatusChanged="micStatusChanged"
            @screenShare="screenShare"
            @toggleDevices="toggleDevices"
            @stopScreenShare="stopScreenShare"
            @toggleFullscreen="toggleFullscreen"
            @leaveSession="leaveSession"
            @toggleChat="toggleChat"
            @audioFilter="applyGStreamerFilterAudio"
            @videoFilter="applyFaceOverlayFilter"
            @audioCustomFilter="audioCustomFilter"
            @startRecording="startRecording"
            @stopRecording="stopRecording"
            @showFixedAlert="showFixedAlert=!showFixedAlert"
        />
      <b-modal :id="'modalRoomAlert'" size="lg" hide-footer="" :header-class="'checkHeader'">
        
        <h4>{{$t('READ THE INSTRUCTIONS') + ' - ' + $t('LIVE INTERVIEW')}}</h4>
        <p> 
          <strong>{{$t('Hover your mouse over the picture/avatar to see management icons')}} </strong>
          <ul>
            <li> {{$t('In the Evaluation icon')}}: (<i class="fa fa-tasks" />) {{$t('You can write and save reviews about the evaluated person')}} </li>
            <li> {{$t('On the Mutar icon')}}: (<i class="fa fa-microphone-slash" />) {{$t('You can mute the microphone of a participant')}} </li>
            <!-- <li> {{$t('A pessoa não saberá que você virou a tela, a não ser que você comunique')}} </li> -->
            <li v-if="permitRecord"> {{$t('If you want to record the interview, ask for authorization to participants and record the screen with this authorization')}} </li>
          </ul>
        </p>
        <div class="text-center">
          <b-button variant="success" size="lg"  
            @click="initializeRoom"
            :disabled="!introReadTimeDone"
            v-promise-btn="{promise: promiseInit}"> 
            {{$t("Let`s Go")}} !
          </b-button> 
        </div>
      </b-modal>
      <b-modal :id="'modalBlindRoomAlert'" size="md" 
        hide-footer hide-header>
        
        <p class="text-center" >
          <b-img :src="'https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/gifs/empresa/explain_'+$i18n.locale+'.gif'" ></b-img>
        </p>
        <div class="text-center">
          <b-button variant="success" size="lg" 
            :disabled="!introReadTimeDone"
            @click="initializeRoom"
            v-promise-btn="{promise: promiseInit}"> 
            {{$t("Let`s Go")}} !
          </b-button> 
        </div>
      </b-modal>
      <b-modal :id="'modalOptimizeExp'" 
        size="md" 
        hide-footer 
        hide-header
        >
        <div class="r-font">
          <OptimizeExperienceContent  />
          
          <div class="text-center">
            <b-button variant="dark" size="lg"  @click="$bvModal.hide('modalOptimizeExp')"> 
              {{$t("OK, I got it!")}}
            </b-button> 
          </div>
        </div>
      </b-modal>
       <SidebarCreateAvaliacao id="sidebarAvaliacaoAoVivo" :coUID="'000-000-000'" 
        v-model="sidebarEvalVisible" 
        :raToken="currentRAToken" 
        :publicEmail="userEmail"
        :publicName="userName"
        :candidato="selectedCandidato" />
       <SidebarBackgroundImage id="sidebarBackground" v-model="sidebarBackground" @selected="imageSelected" @blur="blurSelected" /> 
       <SidebarManualLiveRoom id="sidebarManualLiveRoom_1" v-model="sidebarManual1Visible"> 
         <b-card>
           <b-card-body>
              <p>{{$t('The interviewees voice and image will be altered and they will be given the name of a famous city or country')}}</p>
              <p> 
                <strong>{{$t('Hover the mouse over the figure / avatar to see management icons')}}: </strong>
                <ul>
                  <li> {{$t('Filter icon')}}: (<i class="fa fa-filter" />) {{$t('Allows you to change the tone of the person’s voice')}} </li>
                  <li> {{$t('Reveal icon')}}: (<i class="fa fa-eye" />) {{$t('Allows you to reveal the person 10 minutes after the interview commences. Once the person is revealed, their status cannot be reverted.')}} </li>
                  <li> {{$t('In the Evaluation icon')}}: (<i class="fa fa-tasks" />) {{$t('You can write and save reviews about the evaluated person')}} </li>
                  <li> {{$t('Anonymous Resume')}} (<i class="fa fa-user-secret" ></i>) {{$t('Provides access to the anonymous resume of the person applying. You will not have access to the personal information of the person being evaluated.')}}</li>
                  <!-- <li> {{$t('A pessoa não saberá que você virou a tela, a não ser que você comunique')}} </li> -->
                  <li v-if="permitRecord"> {{$t('If you want to record the interview, ask for authorization to participants and record the screen with this authorization')}} </li>
                </ul>
              </p>
           </b-card-body>
         </b-card>
       </SidebarManualLiveRoom>
       <AlertFixedContent v-model="showFixedAlert" :blindEnabled="blindEnabled" />
    </div>
  </transition>
</template>

<script lang="ts">
import SidebarCreateAvaliacao from '@/components/avaliacao/sidebar/SidebarCreateAvaliacao.vue';
import SidebarBackgroundImage from '@/components/sidebar/SidebarBackgroundImage.vue';
import SidebarManualLiveRoom from '@/components/sidebar/SidebarManualLiveRoom.vue';
import { siteCandidateEndPoint } from "@/core/endpoint";
import { EmpresaEntity, RecrutadorEntity } from '@/core/models/Entities';
import { LocalDataManagement } from '@/core/util';
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VideoRoomMixin from './mixins/VideoRoomMixin';
import AlertFixedContent from './partials-video/AlertFixedContent.vue';
import OptimizeExperienceContent from './partials-video/OptimizeExperienceContent.vue';
import Chat from './partials-video/Chat.vue';
import Stream from './partials-video/Stream.vue';
import Toolbar from './partials-video/Toolbar.vue';
import TopNavBar from './partials-video/TopNavBar.vue';

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};

// let localUser = new UserModel();

@Component({
  components: {
    Stream,
    Chat,
    Toolbar,
    SidebarCreateAvaliacao,
    TopNavBar,
    SidebarManualLiveRoom,
    SidebarBackgroundImage,
    AlertFixedContent,
    OptimizeExperienceContent
  },
  mixins: [VideoRoomMixin]
})
export default class RoomManager extends Vue {
  public URL: string = location.protocol + "//" + location.host;
  public URLcandidato: string = siteCandidateEndPoint;
  public currentVideoDeviceId:string = "";
  public currentAudioDeviceId:string = "";
  public promiseInit: Promise<any>|null = null;
  public introDone: boolean =false;
  public introReadTimeDone: boolean =false;

  @Getter("companyInfo", { namespace: 'profile' })
  public empresa?: EmpresaEntity;

  @Getter("recruiterInfo", { namespace: 'profile' })
  public recrutador?: RecrutadorEntity;

  @Action("fetchEmpresaInfo", { namespace: 'profile' })
  public fetchEmpresaInfo: any;

  public IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get primeiroNome() {
    return (!this.IsRecruiter()) ? this.empresa!.primeironome||null : this.recrutador!.nome;
  }
  
  get userId() {
    return (!this.IsRecruiter()) ? this.empresa!.id : this.recrutador!.id;
  }

  get permitRecord () {
    return (this.empresa && this.empresa!.configuracoes && this.empresa!.configuracoes['REC_ENABLED'] == true);
  }

  
  public openFirstModal() {
    console.log("openFirstModal")
    const _this: any = this;
    if(!_this.platform.canUseMediaPipe() && !LocalDataManagement.getLocalData('JB_M_OPTIMAL')?.opened) {
      this.$bvModal.show('modalOptimizeExp')
    } else {
      if (_this.room.entidade.blind == true) {
        this.$bvModal.show('modalBlindRoomAlert')
      } else {
        this.$bvModal.show('modalRoomAlert')
      }
    }
  }
  

  public modalListener() {
    this.$root.$on('bv::modal::hide', async (bvEvent, modalId) => {
      if(modalId == "modalRoomAlert" || modalId == "modalBlindRoomAlert") {
          await sleep(1);
          if (!this.introDone) {
            console.log('modalId not clicked on button must initialize Room')
            this.initializeRoom()
          }
      }
      if(modalId == "modalOptimizeExp" ) {
        console.log("modalOptimizeExp - hide")
        LocalDataManagement.saveLocalData('JB_M_OPTIMAL', {opened:true},null)
        this.openFirstModal()
      } 
    })
  }

  public async mounted() {

    this.modalListener();
    
    document.body.style.overflow = 'auto';
    const _this: any = this;

    setTimeout(() => { 
      this.introReadTimeDone = true
    },10000);
    
    if (!!sessionStorage.getItem('access_token')) {
      await this.loggedInUserStartUP()
    } else {
      this.publicUserStartUp()
    }
    
    window['vrm'] = this;
  }

  public async publicUserStartUp() {
    const _this: any = this;
    // check if room is blind by the parameter passed on url
    try {
      _this.room.entidade = await _this.publicRoom.fetchPublicRoomInfo(this.$route.params.sid);
      if (_this.room.entidade && _this.room.entidade.key) {
        if(_this.room.entidade.supplier == 4) { // supplier 4 is the supplier of zoom
          window.location.href = `https://room.jobecam.com/manager/${_this.room.entidade.key}`;
          return;
        }
        _this.ovManager.enableFaceMesh = _this.room.entidade.enableFaceMesh && _this.platform.canUseMediaPipe();
        _this.ovManager.enableCustomBack = _this.room.entidade.enableCustomBack && _this.platform.canUseMediaPipe();
        _this.ovManager.blockApprove = _this.room.entidade.blockApprove;
        this.openFirstModal()
        return;
      } else {
        _this.roomError = true;
        _this.loadingPage = false;
      }
    } catch (e) {
      console.log(e)
      _this.roomError = true;
      _this.loadingPage = false;
    }
    // await _this.openTestVideo();
    // _this.loadingPage = false;
  }

  public async loggedInUserStartUP() {
    const _this: any = this;
    try {
      await _this.room.findByKey(this.$route.params.sid);
      if (_this.room.entidade && _this.room.entidade.id) {
        if(_this.room.entidade.supplier == 4) { // supplier 4 is the supplier of zoom
          window.location.href = `https://room.jobecam.com/host/${_this.room.entidade.key}`;
          return;
        }
        _this.ovManager.enableFaceMesh = _this.room.entidade.enableFaceMesh;
        _this.ovManager.enableCustomBack = _this.room.entidade.enableCustomBack;
        this.openFirstModal()
        return;
      } else {
        _this.roomError = true;
        _this.loadingPage = false;
      }
    } catch (e) {
      _this.roomError = true;
      _this.loadingPage = false;
    }
  }

  public async initializeRoomParams() {
    const _this: any = this;
    _this.openRoom = true;

    console.log('fetch info from empresa?')
    if (!this.empresa)
      await this.fetchEmpresaInfo();
      
    console.log('setting - uid / guid')
    _this.userName = this.primeiroNome;
    _this.userEmail = this.empresa?.email;
    _this.UID = this.userId;
    _this.GUID = this.empresa?.guid;
    _this.isAuthenticatedUser = !!this.empresa;

    if(!_this.testDone) {
      await _this.openTestVideo();
      return;
    }

    // _this.loadVariables(this.userId, sessionStorage.getItem("profile_type"), this.empresa?.guid);
    // _this.createOpenviduCall();
    // await _this.openTestVideo();
    // this.currentVideoDeviceId = _this.ovManager.currentVideoDeviceId;
    
  }

  public async initializeRoom() {
    console.log('initializeRoom')
    this.introDone = true;
    this.promiseInit = new Promise(async (resolve: any, reject: any) :Promise<void> => {
      try {
        const _this: any = this;
        if (sessionStorage.getItem('access_token')) {
          this.initializeRoomParams();
        } else {
          await _this.openTestVideo();
          // this.currentVideoDeviceId = _this.ovManager.currentVideoDeviceId;
        }
        this.$bvModal.hide('modalRoomAlert')
        this.$bvModal.hide('modalBlindRoomAlert')
      } catch(e) {
        console.error("Room Could not be initialized", e);
      } finally {
        resolve();
      }
    })
  }

}
</script>

<style lang="css" src="@/assets/css/live-interview/live-interview-root.css"></style>